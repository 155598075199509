@media (max-width: 575px) {

    h1 span {
        font-size: 2.25rem;
    }

    h3 {
        font-size: 1.4rem;
    }
    
    #auth-panel .card {
        width: 30rem;
    }

    #auth-panel .back{
        margin-top:1.5rem;
    }

    #auth-panel .logo img {
        width: 12rem;
        margin: 4rem 0 3rem 0;
    }

    header .top-panel:before, 
    header .top-panel:after, 
    #player:before, 
    #player:after {
        width: 300px;
        height: 300px;
    }
    header .top-panel li {
        position: initial;
    }
    header .top-panel li ul {
        top: 4.7rem;
        left:1rem;
        right:1rem;
        padding: 0rem;
    }

    #player .progress-bar-content div.progress-bar,
    #player .progress-bar-content div.progress-bar .progress {
        width: 200px;
    }

    #audio-recording .audio-desc-container{
        height: 150px;
    }

    #audio-recording .play-list-container{
        height: calc(100% - 150px);
    }

    #payment-form .membership{
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
    #payment-form .membership img {
        height: 125px;
        margin-right: 0rem;
    }
    
    header .side-bar {
        padding-top:2rem;
    }    

    #audio-recording .audio-desc p.info, 
    .modal-body .audio-desc p.info {
        font-size: 1.4rem;
    }

    .ReactModal__Content .modal-body {
        background-color: var(--background-color);
        padding: 1.5rem;
    }
    
    #auth-panel .langs div.all-langs .container{
        width: 100%;
        max-width: 100%;
    }
    #auth-panel .langs div.all-langs ul li{
		max-width:calc(50% - 1rem);
		flex:0 0 calc(50% - 1rem);
		cursor:pointer;
		margin: 0 0.5rem 1rem 0.5rem;
        line-height: 1.3rem;
	}

    .card:before,
    .card:after{
        width:300px;
    }
    
    .ReactModal__Content .close {
        height: 22px;
    }

    .col-xs-12{
        flex: 0 0 100%;
        max-width: 100%;
    }

}
	