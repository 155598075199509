@media (max-width: 991px) {

    h1 span {
        font-size: 2.8rem;
    }

    #audio-recording .audio-desc p.info, 
    .modal-body .audio-desc p.info {
        font-size: 1.6rem;
    }
	
    header .side-bar {
        width: 180px;
    }
    header {
        padding: 0 0 0 18rem;
    }

    header .top-panel:before {
        left: 180px;
    }

    header .side-bar .course.main img {
        width:100%;
    }

    #player .progress-bar-content div.progress-bar,
    #player .progress-bar-content div.progress-bar .progress {
        width: 600px;
    }

    #auth-panel .langs div.all-langs .container{
        width: 768px;
        max-width: 768px;
    }

    #auth-panel .langs div.all-langs ul li{
		max-width:calc(25% - 2rem);
		flex:0 0 calc(25% - 2rem);
		cursor:pointer;
		margin: 0 1rem 1rem 1rem;
	}

    #video-player-overlay {
        left: 180px;
    }

    .l-content {
        flex: 1 1;
        padding: 0 1.5rem 0 19.5rem;
    }
    .col-md-12{
        flex: 0 0 100%;
        max-width: 100%;
    }	

    .ReactModal__Content{
        width:90%;
      }
}