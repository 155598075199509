@media (max-width: 1200px) {

    h1{
        margin-bottom:0px;
    }
    h1 span {
        font-size: 3.5rem;
    }

    p {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    header .side-bar .logo{
        width:80px;
    }
    header .side-bar .course {
        padding: 1rem 2rem;
    }
    header .side-bar h3 {
        margin: 0 0 0 0;
    }
    
    #auth-panel .logo img {
        width: 14rem;
        margin: 5rem 0 3rem 0;
    }
    input,select {
        padding: 1.2rem;
    }

    #play-list .static.smallest {
        width: 24px;
    }
    #play-list .static {
        width: 82px;
    }

    #play-list .static.smaller {
        width: 60px;
    }
    #audio-recording .audio-desc p.info,
    .modal-body .audio-desc p.info {
        font-size: 1.8rem;
        margin-top: 0.5rem;
    }

    #audio-recording .audio-desc .more-info{
        display:block;
    }
    #audio-recording .audio-desc .description{
        display:none;
    }
    #audio-recording .audio-desc p .circle,
    .modal-body .audio-desc p .circle{
        margin: 0 1rem 0.3rem 1rem;
    }

    #audio-recording .audio-desc {
        padding: 0 0 0 0;
        text-align: center;
        width:100%;
    }

    #audio-recording .audio-desc-container{
        height: 180px;
        flex:none !important;
    }

    #audio-recording .play-list-container{
        height: calc(100% - 180px);
    }

    #player .progress-bar-content div.progress-bar,
    #player .progress-bar-content div.progress-bar .progress {
        width: 800px;
    }

    #play-list{
        margin-top:0;
        width: 100%;
        height: auto;
        padding: 1rem 1rem 0 1rem;
    }
    #play-list * {
        font-size: 1.2rem;
    }
    #play-list .pl-body {
        height: calc(100% - 20px);
        max-height: calc(100% - 20px);
    }

    #player .controls-content {
        width: 910px;
        position: relative;
    }
    
    #play-list .pl-item div span.first {
        width: 2.4rem;
        height: 2.4rem;
    }
    #play-list .pl-item div span {
        width: 2.2rem;
        height: 2.2rem;
        font-size:1.2rem;
    }
    #play-list .pl-item div span.first.playing:before, 
    #play-list .pl-item:hover div span.first:before {
        width: 22px;
        height: 22px;
    }
    #play-list .pl-item div span.first.playing:after {
        background-size: 20px 20px;
        width: 22px;
        height: 22px;
    }
    #play-list .pl-item:hover div span.first:not(.playing):after {
        background-size: 11px 11px;
        width: 11px;
        height: 11px;
        left: 7px;
    }
    #play-list .pl-item {
        margin-top: 0.5rem;
    }
    #play-list .pl-header {
        padding-bottom: 0.5rem;
    }
    #video-player-overlay .close{
        width:30px;
        top:2rem;
        right:2rem;
    }
    #audio-recording .audio-desc.full {
        padding: 0 2rem 2rem;
    }
    #audio-recording .audio-desc.full .btn{
        display:none;
    }
    .card .card-header, .modal-header {
        padding: 1.5rem 5.5rem;
    }
    .card .card-header.padd, .modal-header.padd {
        padding: 1rem 5.5rem;
    }
    .card .card-header:after, 
    .card .card-header:before, 
    .modal-header:after, 
    .modal-header:before {
        top: 20px;
        height: 10px;
        width:10px;
    }
    .card .card-header.padd:after, 
    .card .card-header.padd:before, 
    .modal-header.padd:after, 
    .modal-header.padd:before {
        top: 18px;
    }
    header .side-bar .dot, 
    #player .dot {
        height: 10px;
        width: 10px;
    }
    #player .text-controls h3 span{
        font-size:1.6rem
    }
    #player .text-controls .main-controls {
        padding: 1rem 0 1rem;
    }

    .au-container{
        flex-direction: column;
    }

    #auth-panel .langs div.all-langs .container{
        width: 992px;
        max-width: 992px;
    }
	
    .play-list-container{
        flex:1 !important;
        display: flex;
    }

    .input-group input {
        padding: 1.2rem 1.2rem 1.2rem 0;
    }

    .btn {
        font-size: 1.2rem;
        padding: 1.2rem 2.2rem 1rem 2.2rem;
        font-weight:800;
    }
    .btn-thiner {
        padding: 0.5rem 2rem 0.5rem 2rem;
    }

	.col-lg-12{
        flex: 0 0 100%;
        max-width: 100%;
    }	

}