:root{
  --base-color:#E4E4E4;
  --background-color:#222222;
  --background-color-dark:#111111;
  --background-color-lighter:#333333;
  --line-color:#545454;
  --link-color:#F8AC77;
  --button-color:#6F3810;
  --warning-color:#ffeeba;
  --danger-color:#DD3646;
	--success-color:#81CD34;
}
::-webkit-scrollbar {
  width: 5px!important;
  height: 5px!important;
}
::-webkit-scrollbar-track {
  background: #222222!important;
}
::-webkit-scrollbar-thumb {
  background: #717171!important;
}
*, :after, :before {
  box-sizing: border-box;
}
html{
	scroll-behavior: smooth;
}
html,body {
	font-size:10px;
	color:var(--base-color);
	background-color:var(--background-color);
	font-family:'Barlow', sans-serif;
	font-weight:400;
  height:100%;
}
body{
  background-image: url("../Images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding:0;
  margin:0;
  overflow: hidden;
}
h1 span{
  font-weight: 300;
  font-size: 5.5rem;
  text-transform: uppercase;
  background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  position: relative;
  line-height: 100%;
}
h3 span{
  font-weight: 300;
  font-size: 2.5rem;
  text-transform: uppercase;
  background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  position: relative;
  line-height: 100%;
}
h3{
  font-size:1.6rem;
}
body.ReactModal__Body--open{
	overflow:hidden;
}
:focus {
    outline:0px none;
}
a,
a:hover{
	color:var(--link-color);
	cursor:pointer;
  font-size:1.4rem;
  transition: 0.5s color;
}
p,li{
  font-size:1.6rem;
  line-height: 2rem;
}
label{
  font-size:1.4rem;
  font-weight: 600;
  margin-bottom:0.5rem;
  display: block;
}
input,select{
  background-color: var(--background-color);
  border:0px none;
  border-radius: 1rem;
  color: var(--base-color);
  padding: 1.5rem;
  font-size: 1.4rem;
  border: 1px solid var(--line-color);
  width:100%;
}
select option{
  padding:0rem;
}
header{
  z-index: 2;
  padding: 0 0 0 23rem;
  position:relative;
}
header .menu-icon{
  margin-right:auto;
  display:none;
}
header .top-panel .menu-icon img{
  margin-left:0;
}
header .top-panel{
  margin:0;
  display: flex;
  margin-left:auto;
  list-style-type: none;
  background: rgba(63, 63, 63, 0.5);
  padding:0.8rem 1.5rem;
  justify-content: flex-end;
}
header .top-panel:before {
  content: " ";
  width:800px;
  height: 800px;
  position:absolute;
  left:230px;
  top:0px;
  pointer-events: none;
  z-index: 0;
  background: radial-gradient(100% 100% at 0% 0%, rgba(241, 155, 91, 0.3) 0%, rgba(34, 34, 34, 0) 100%);
}
header .top-panel:after {
  content: " ";
  width:800px;
  height: 800px;
  position:absolute;
  right:0px;
  top:0px;
  pointer-events: none;
  z-index: 0;
  background: radial-gradient(100% 100% at 100% 0%, rgba(146, 222, 255, 0.3) 0%, rgba(34, 34, 34, 0) 100%);
}
header .top-panel img{
  height:3rem;
  margin-left:1.5rem;
  cursor:pointer;
}
header .top-panel li{
  line-height: 0;
  position:relative;
}
header .top-panel .menu-overlay{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 2;
}
header .top-panel li ul{
  position:absolute;
  top:3.8rem;
  right:0;
  list-style-type: none;
  background-color: var(--background-color);
  padding:1rem;
  z-index: 3;
  border-radius:0 0 1rem 1rem;
  min-width: 30rem;
  overflow: hidden;
  display:none;
  box-shadow: 0 10px 15px rgba(0,0,0,0.4);
}
header .top-panel li ul.open{
  display:block;
}
header .top-panel li ul li{
  border-bottom:1px solid var(--line-color);
}
header .top-panel li ul li:last-child{
  border-bottom:0px none;
}
header .top-panel li ul li a{
  display:flex;
  align-items: center;
  padding:1rem;
  color:var(--base-color);
  text-decoration: none;
}
header .top-panel li ul li a:hover{
  color:var(--link-color);
}
header .top-panel li ul li img{
  margin: 0 1rem 0 0;
  height:2rem;
}
header .side-bar{
  width:230px;
  position:fixed;
  left:0;
  top:0;
  bottom:0;
  background-color: var(--background-color);
  text-align:center;
  padding-top:3rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}
header .side-bar .logo{
  width:50%;
}
header .side-bar .categories{
  overflow-y: auto;
  overflow-x: hidden;
  flex:1;
  margin-top:3rem;
  border-top:1px solid var(--line-color);
  padding-bottom:150px;
  width:100%;
}
header .side-bar h3{
  margin:0.5rem 0 0 0;
  text-transform: uppercase;
  position:relative;
  z-index: 1;
}
header .side-bar .dot,
#player .dot{
  background: linear-gradient(180deg, #FFE2CC 0%, #FFEDDF 37.5%, #FFA96A 100%);
  border-radius: 50%;
  content: " ";
  height: 15px;
  position: absolute;
  top: 15px;
  width: 15px;
}

header .side-bar .course{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom:1px solid var(--line-color);
  position:relative;
}

header .side-bar .course img{
  position:relative;
  z-index: 1;
}
header .side-bar .course:not(.selected):hover:before{
  position:absolute;
  content: " ";
  width:100%;
  height:60px;
  bottom:0;
  left:0;
  z-index:0;
  opacity:0.5;
  background: radial-gradient(50% 50% at 50.24% 100%, rgba(237, 204, 180, 0.4) 0%, rgba(34, 34, 34, 0) 100%);
}
header .side-bar .course.selected:before{

  position:absolute;
  content: " ";
  width:100%;
  height:60px;
  bottom:0;
  left:0;
  z-index:0;
  background: radial-gradient(50% 50% at 50.24% 100%, rgba(237, 204, 180, 0.4) 0%, rgba(34, 34, 34, 0) 100%);

}

header .side-bar .dot.tl,
#player .dot.tl{
  left:15px;
}
header .side-bar .dot.tr,
#player .dot.tr{
  right:15px;
}

header .side-bar .course{
  text-align: center;
  padding:2rem;
  color:var(--base-color);
  text-decoration: none;
  display:block;
}

header .side-bar:before,
header li ul:before {
  content: " ";
  width:130px;
  height: 130px;
  position:absolute;
  left:0px;
  top:0px;
  pointer-events: none;
  z-index: 0;
  background: radial-gradient(100% 100% at 0% 0%, rgba(241, 155, 91, 0.3) 0%, rgba(34, 34, 34, 0) 100%);
}
header .side-bar:after,
header li ul:after {
  content: " ";
  width:130px;
  height: 130px;
  position:absolute;
  right:0px;
  top:0px;
  pointer-events: none;
  z-index: 0;
  background: radial-gradient(100% 100% at 100% 0%, rgba(146, 222, 255, 0.3) 0%, rgba(34, 34, 34, 0) 100%);
}
header .side-bar .course img {
  max-width:60%;
}
header .side-bar .course.main img {
  max-width:100%;
}
header .side-bar .course .badge {
  position:absolute;
  z-index: 1;
  top:0;
  right:0;
  padding:0.5rem 1rem;
  border-radius:0 0 0.5rem 0.5rem;
  font-size: 1.3rem;
  line-height: 1.3rem;
}
header .side-bar .course .badge.badge-purple-gold {
  background :linear-gradient(180deg, #FFE2CC 0%, #FFEDDF 37.5%, #FFA96A 100%);
  color:#222;
  font-weight:700;
}
header .side-bar .bottom-gradient {
  position:absolute;
  bottom:120px;
  pointer-events: none;
  right:0;
  width:100%;
  height:75px;
  background: linear-gradient(0deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
  z-index: 1;
}
#root{
  height:100%;
}
#auth-panel{
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#auth-panel .card{
  width:50rem;
}
#auth-panel .logo img{
  width:17rem;
  margin:10rem 0 6rem 0;
}
#auth-panel .signpost img{
  max-width:100%;
}
#auth-panel .signpost .col-6{
  padding:10px;
}
#auth-panel .langs{
  margin-left:10px;   
  position:relative; 
  display: flex;
  background-color: var(--background-color);
  padding:4px;
  border-radius:10px;
  width:62px;
}
#auth-panel .langs .border-panel{
  background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
  padding:1px;
  border-radius: 1rem;
  margin-left:auto;
  position:relative;   
  z-index: 2;
}
#auth-panel .langs .inner-panel{
  background-color: var(--background-color);
  border-radius: 1rem;
  display:flex;
  padding:5px;
  position:relative;
  z-index: 1;
}
#auth-panel .langs img{
  border-radius: 0.5rem;
  cursor: pointer;
  z-index: 1;
  width:100%;
}

#auth-panel .langs div.all-langs{
  position: fixed;
  top:0;
  right:0;
  background-color: var(--background-color);
  padding: 0px 6px 6px 6px;
  z-index: 901;
  width:100%;
  padding:2rem;
  overflow-x: hidden;
  overflow-y: auto;
  bottom: 0;
  transform: translateY(calc(100%));
  transition: transform 1s;
}
#auth-panel .langs div.all-langs .container{
  width: 1200px;
  max-width: 1200px;
  margin:0 auto;
}
#auth-panel .langs div.all-langs.open{
  transform: translateY(0);
}
#auth-panel .langs div.all-langs ul{
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin:0 0 3rem 0;
  padding:0;
}
#auth-panel .langs div.all-langs ul li{
  max-width:calc(20% - 2rem);
  flex:0 0 calc(20% - 2rem);
  cursor:pointer;
  margin: 0 1rem 1rem 1rem;
  display: flex;
  align-items: center;
}
#auth-panel .langs div.all-langs ul li img{
  margin-right:1rem;
  border-radius: 0.5rem;
  height:2.5rem;
  width:auto;
}
#auth-panel .langs div.all-langs h3{
  margin-bottom:3rem;
}
#auth-panel .langs div.all-langs .close{
  position:absolute;
  top:20px;
  right:25px;
  font-size:14px;
  font-weight:bold;
  cursor:pointer;
  color:#fff;
  z-index: 1;
  background-color: transparent;
  border: 0px none;
}

#player{
  height:120px;
  width:100%;
  position: relative;
  z-index: 20;
  background-color: var(--background-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display:flex;
  justify-content: center;
  align-items: center;
}
#player:before {
  content: " ";
  width:750px;
  height: 750px;
  position:absolute;
  left:0px;
  top:0px;
  pointer-events: none;
  z-index: 0;
  background: radial-gradient(100% 100% at 0% 0%, rgba(241, 155, 91, 0.3) 0%, rgba(34, 34, 34, 0) 100%);
}
#player:after {
  content: " ";
  width:750px;
  height: 750px;
  position:absolute;
  right:0px;
  top:0px;
  pointer-events: none;
  z-index: 0;
  background: radial-gradient(100% 100% at 100% 0%, rgba(146, 222, 255, 0.3) 0%, rgba(34, 34, 34, 0) 100%);
}
#player .controls-content{
  width:1000px;
  position: relative;
}
#player .controls-content.text-controls{
  width:100%;
  padding:0 3rem;
  position: relative;
}
#player .controls-content audio{
  position:absolute;
  opacity:0;
}
#player .main-controls{
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 1rem;
}
#player .text-controls .main-controls{
  padding: 2rem 0 1rem;
}
#player .text-controls h3 span{
  font-size:2rem
}
#player .main-controls .play{
  margin: 0 2rem;
}
#player .main-controls img{
  cursor:pointer;
}
#player .main-controls .prev-next-space{
  display:inline-block;
  padding:0 2rem;
}
#player .progress-bar-content{
  display:flex;
  justify-content: center;
  align-items: center;
  padding-bottom:2rem;
  position: relative;
  z-index: 1;
}
#player .progress-bar-content div{
  font-size:1.2rem;
}
#player .progress-bar-content div.time{
  width:45px;
  text-align:center;
}
#player .progress-bar-content div.progress-bar{
  width:900px;
  height:6px;
  margin:0 1rem;
  position:relative;
  border-radius:5px;
  overflow: hidden;
  background-color: #000;
  cursor:pointer;
}
#player .progress-bar-content div.progress-bar .progress{
  width:900px;
  background: linear-gradient(180deg, #F8AC77 40%, #B2E7FE 100%);
  height:6px;
  border-radius:5px;
  transform: translateX(-50%);
  pointer-events: none;
}

#player .volume-content{
  position:absolute;
  z-index: 1;
  right:7px;
  top:40px;
  width:150px;
  display: flex;
  align-items: center;
}
#player .volume-content img{
  margin-right:1.5rem;
}
#player .volume-content .volume{
  width:100%;
  height:4px;
  position:relative;
  background-color: #000;
  border-radius: 5px;
}
#player .volume-content .volume .progress{
  width:30%;
  height:4px;
  position:absolute;
  top:0;
  left:0;
  background: linear-gradient(180deg, #F8AC77 40%, #B2E7FE 100%);
  border-radius: 5px;
}
#player .volume-content .volume .thumb{
  width:18px;
  height:18px;
  border-radius: 9px;
  position: absolute;
  left:0%;
  top:-7px;
  background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
  cursor:pointer;
}
#audio-recording{
  height:100%;
}
#audio-recording .audio-desc{
  padding:0 0 0 3rem;
}
#audio-recording .audio-desc.full{
  overflow-y: auto;
  margin:5rem 0 0 0;
  padding: 0 0 5rem 3rem;
}
#audio-recording .audio-desc .more-info{
  display:none;
}
#audio-recording .audio-desc p.info,
.modal-body .audio-desc p.info{
  font-size:2.5rem;
}
#audio-recording .audio-desc p .circle,
.modal-body .audio-desc p .circle{
  display:inline-block;
  margin:0 1.5rem 0.5rem 1.5rem;
  width:6px;
  height:6px;
  border-radius: 3px;
  background-color: var(--base-color);
}
#audio-recording .audio-desc p.description{
  margin-bottom:0;
}

#audio-recording .audio-desc-container .description{
  max-height: 40vh;
  overflow-y: auto;
  padding-right:1.5rem;
}
#audio-recording .audio-desc.full .description{
  max-height: none;
  overflow-y: initial;
}

#audio-recording .audio-desc.full .description h1{
  font-size:3.5rem;
}
#audio-recording .audio-desc.full .description h2{
  font-size:3rem;
}
#audio-recording .audio-desc.full .description h3{
  font-size:2.5rem;
}
#audio-recording .audio-desc.full .description h4,
#audio-recording .audio-desc.full .description h5,
#audio-recording .audio-desc.full .description h6{
  font-size:2rem;
}
#audio-recording .audio-desc.full .description img{
  max-width:100%;
  height:auto;
}

#audio-recording .audio-desc-container,
#audio-recording .play-list-container{
  height:100%;
}

#play-list{
  background: rgba(17, 17, 17, 0.6);
  border-radius: 10px 10px 0px 0px;
  padding:2rem 2rem 0 2rem;
  margin-top:5rem;
  height: calc(100% - 5rem);
  overflow: hidden;
}
#play-list div{
  flex:1;
}
#play-list .static{
  width:100px;
  flex:none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#play-list .static.smallest{
  width:30px;
  flex:none;
}
#play-list .static.smaller{
  width:65px;
  flex:none;
}
#play-list *{
  font-size:1.4rem;
  width: 100%;
}
#play-list .pl-header{
  padding-bottom:1rem;
  border-bottom: 1px solid var(--line-color);
}
#play-list .pl-body{
  height: calc(100% - 28px);
  max-height: calc(100% - 28px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom:10px;
  width: calc(100% + 5px);
}
#play-list .pl-header,
#play-list .pl-item{
  display:flex;
}
#play-list .pl-item{
  margin-top:1rem;
  cursor:pointer;
}

#play-list .pl-header *{
  font-weight:600;
}
#play-list .pl-header div{
  padding:0 1rem;
}
#play-list .pl-item div{
  padding:0 1rem;
  display: flex;
  align-items: center;
  line-height: 100%;
}
#play-list .pl-item div.text-center{
  justify-content: center;
}

#play-list .pl-item.selected *,
#play-list .pl-item.selected div.viewed{
  color:var(--link-color);
}
#play-list .pl-item div span.first{
  width:3rem;
  height:3rem;
  padding:1px;
  border-radius: 0.5rem;
  background-color: var(--background-color-lighter);
  position:relative;
}
#play-list .pl-item div span.first.playing{
  background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
}
#play-list .pl-item div span.first.playing:before,
#play-list .pl-item:hover div span.first:before{
  content:" ";
  background-color: var(--background-color-lighter);
  width:28px;
  height:28px;
  position:absolute;
  z-index: 1;
  top: 1px;
  left: 1px;
  border-radius: 0.5rem;
}
#play-list .pl-item div span.first.playing:after{
  content:" ";
  background-image: url("../Images/Icons/volume-on.svg");
  background-size: 25px 25px;
  width:25px;
  height:25px;
  position:absolute;
  z-index: 2;
  top: 2px;
  left: 3px;
}
#play-list .pl-item:hover div span.first:not(.playing):not(.reading):not(.to-read):after{
  content:" ";
  background-image: url("../Images/Icons/play.svg");
  background-size: 15px 15px;
  width:15px;
  height:15px;
  position:absolute;
  z-index: 2;
  top: 7px;
  left: 8px;
}
#play-list .pl-item:hover div span.first.to-read:after,
#play-list .pl-item:hover div span.first.reading:after{
  content:" ";
  background-image: url("../Images/Icons/list.svg");
  background-size: 15px 15px;
  width:15px;
  height:15px;
  position:absolute;
  z-index: 2;
  top: 7px;
  left: 8px;
}
#play-list .pl-item div span{
  width:2.8rem;
  height:2.8rem;
  background-color: var(--background-color-lighter);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight:bold;
  border-radius: 0.5rem;
}
#play-list .pl-item:hover .title{
  color:var(--link-color);
}
#play-list .pl-item:hover .first{
  background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
}
#notification{
  position:fixed;
  width:300px;
  bottom:140px;
  right:20px;	
  z-index:99999;
}
#notification .notify{
  margin-top:15px;
  padding:0px;
  background-color:transparent;
}
#notification .notify .alert{
  margin-bottom:0px;	
}
#video-player-overlay{
  position:fixed;
  top:0;
  left:230px;
  right:0;
  bottom:120px;
  background-color: rgba(0,0,0,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity:1;
}
#video-player-overlay .close{
  position:absolute;
  top:4.3rem;
  right:4.3rem;
  cursor:pointer;
}
#video-player-overlay.hidden{
  opacity:0;
  pointer-events: none;
}
#video-player-overlay #video-player{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width:auto;
  aspect-ratio: 16/9;
  position:relative;
}
#video-player-overlay #video-player.portrait{
  width: 90%;
  height:auto;
}

#video-player-overlay #video-player iframe{
  box-shadow: 0 0 25px 10px #000;
  border: 1px solid #4b4b4b;
  border-radius:1.5rem;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
#payment-form .StripeElement{
	padding: 20px;
  background: #121212;
  border-radius: 1rem 1rem 0 0;
}
#payment-form .payment-button-container{
  margin-top:1rem;
}
#payment-form .pay-button{
  border-radius:0 0 1rem 1rem;
}
#payment-form .membership{
  display:flex;
  flex-wrap: wrap;
}
#payment-form .membership .membership-desc{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex:1;
}
#payment-form .membership img{
  height:200px;
  margin-right:1rem;
}
#payment-form .price-content{
  margin-bottom:2rem;
  background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
  padding:1px;
  border-radius: 1rem;
  margin-left:auto;
  position:relative;   
  z-index: 2;
}

#payment-form .border-panel{
  background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
  padding:1px;
  border-radius: 1rem;
  margin-left:auto;
  position:relative;   
  z-index: 2;
}
#payment-form .inner-panel{
  background-color: var(--background-color-dark);
  border-radius: 1rem;
  display:flex;
  padding:5px;
  position:relative;
  z-index: 1;
  justify-content: center;
}

#payment-form .price{
  padding:1rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size:3rem;
  font-weight:700;
  background-color: #121212;
  border-radius:1rem;
}
#payment-form .price p{
  margin:0;
  text-transform: uppercase;
}
#payment-form .price span{
  background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
#payment-form .bonuses{
  margin-bottom:2rem;
}
#payment-form .bonuses .bonus{
  display:flex;
  align-items: center;
  margin-bottom:1rem;
}
#payment-form .bonuses .bonus img{
    height:75px;
    margin-right:1rem;
}
.whole-content{
  padding: 0;
  display:flex;
  flex-direction: column;
  height:100%;
}
.l-content{
  flex:1;
  padding: 0 2rem 0 25rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.l-content .padd{
  padding: 2rem 0;
}

.card {
  background: var(--background-color);
  border-radius: 1rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  position:relative;
}

.card:before,
.ReactModal__Content:before {
  content: " ";
  width:325px;
  height: 230px;
  position:absolute;
  left:0px;
  top:0px;
  pointer-events: none;
  z-index: 0;
  border-radius:10px;
  background: radial-gradient(100% 100% at 0% 0%, rgba(241, 155, 91, 0.3) 0%, rgba(34, 34, 34, 0) 100%);
}
.card:after,
.ReactModal__Content:after {
  content: " ";
  width:325px;
  height: 230px;
  position:absolute;
  right:0px;
  bottom:0px;
  pointer-events: none;
  z-index: 0;
  border-radius:10px;
  background: radial-gradient(100% 100% at 100% 100%, rgba(146, 222, 255, 0.3) 0%, rgba(34, 34, 34, 0) 100%);
}

.card .card-header,
.modal-header {
  align-items: center;
  border-bottom: 1px solid var(--line-color);
  border-radius: 1rem 1rem 0 0;
  display: flex;
  font-size: 1.8rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.8rem;
  padding: 1.8rem 5.5rem;
  position: relative;
  text-transform: uppercase;
  position:relative;
  z-index: 1;
}
.card .card-header.padd,
.modal-header.padd {
  padding: 1.5rem 5.5rem;
}
.card .card-header span,
.modal-header span{
    background: linear-gradient(133deg, #F8AC77 27.16%, #B2E7FE 73.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.card .card-body {
  padding: 2rem;
  position:relative;
  z-index: 1;
}

.card .card-header:after, 
.card .card-header:before, 
.modal-header:after, 
.modal-header:before {
  background: linear-gradient(180deg, #FFE2CC 0%, #FFEDDF 37.5%, #FFA96A 100%);
  border-radius: 50%;
  content: " ";
  height: 15px;
  position: absolute;
  top: 21px;
  width: 15px;
}
.card .card-header:before, 
.modal-header:before {
  left: 22px;
}
.card .card-header:after, 
.modal-header:after {
  right: 22px;
}
.card-loading-content{
  padding:10rem 0;
}
.form-group{
  margin-bottom:1.5rem;
}
.input-group{
  display:flex;
  border: 1px solid var(--line-color);
  border-radius: 1rem;
  background-color: var(--background-color);
}
.input-group .input-group-prepend{
  width: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:1rem;
  background-color: var(--background-color);
  border-radius: 1rem 0 0 1rem;
}
.input-group .input-group-prepend img{
  max-width:70%;
}
.input-group input{
  flex:1;
  padding: 1.5rem 1.5rem 1.5rem 0;
  font-size: 1.4rem;
  border-radius: 0 1rem 1rem 0;
  border:0px none;
}
.alert{
	font-size:1.4rem;
	border-radius:1rem;
	font-weight: 500;
	margin:1rem 0;
  padding:1rem;
}
.badge-danger{
	background-color:var(--danger-color);
  color:#fff;
}
.badge-success{
	background-color:var(--success-color);
  color:#fff;
}
.badge-warning{
	background-color: var(--warning-color);
  color:#b49a4b;
}
.alert-danger{
	background-color:var(--danger-color);
	color:#fff;
}
.alert-success{
	background-color:var(--success-color);
	color:#fff;
}
.alert-warning{
	background-color: var(--warning-color);
	color:#b49a4b;
}
.btn{
  border-radius: 1rem;
  font-size: 1.4rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  transition: all 0.5s;
  padding: 1.5rem 2.6rem 1.5rem 2.6rem;
  border: 0px none;
  line-height: 1.15;
  cursor: pointer;
}
.btn-thiner{
  padding: 0.5rem 2.6rem 0.5rem 2.6rem;
}
.btn-block{
  display:block;
  width: 100%;
}
.btn-primary{
  background: linear-gradient(180deg, #FFE2CC 0%, #FFEDDF 37.5%, #FFA96A 100%);
  color:var(--button-color);
}
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active{
  background: linear-gradient(180deg, #FFE2CC 0%, #FFEDDF 37.5%, #FFA96A 100%);
  color:var(--button-color);
}
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle {
  background: linear-gradient(180deg, #FFE2CC 0%, #FFEDDF 37.5%, #FFA96A 100%);
  color:var(--button-color);
  border-color: transparent;
}
.btn-danger{
  background: linear-gradient(180deg, #f0838e 0%, var(--danger-color) 100%);
  color:var(--base-color);
}
.ml-auto{
  margin-left:auto;
}
.no-top-margin{
  margin-top:0px !important;
}
.no-bottom-margin{
  margin-bottom:0px !important;
}
.o-hidden{
  overflow: hidden;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.h-100{
  height:100%;
}
.d-flex{
  display:flex;
}
.justify-content-center{
  justify-content: center;
}
.align-items-center{
  align-items: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.col{
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-8 {
  flex: 0 0 66.666%;
  max-width: 66.666%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.flex-1{
  flex:1;
}
.spinner {
  margin: 10px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

button .spinner {
  margin: 0px auto;
  height: 16px;
}

.spinner > div {
  background: linear-gradient(120deg, #F8AC77 27.16%, #B2E7FE 73.03%);
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right:2px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

button .spinner > div {
  margin: 0px 1px;
  height: 16px;
  /*background: transparent;
  background-color: #000;*/
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4);}  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

.ReactModal__Overlay {
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.85) !important;
  z-index:1300 !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content{
  background-color: var(--background-color) !important;
  width:900px;
  bottom:auto !important;
  right:auto !important;
  left:50% !important;
  transform: translateX(-50%);
  border-radius:1rem !important;
  padding:0px !important;
  border:0px none !important;
  overflow: hidden !important;
}
.ReactModal__Content .close{
	opacity:1 !important;
	cursor:pointer;
	margin: 2px 0 0 auto !important;
	padding:0 !important;
	height:24px;
}
.ReactModal__Content .modal-body{
	background-color: var(--background-color);
  padding:2rem;
}
.ReactModal__Content .modal-body .audio-desc img{
	max-width:100%;
  height:auto;
}

.ReactModal__Content .modal-body blockquote,
#audio-recording .audio-desc.full .description blockquote{
  border-left: 4px solid #999;
  margin-left: 1.5rem;
  padding-left: 1.5rem;
}


.ReactModal__Overlay{
  position: fixed; 
  inset: 0px; 
  background-color: rgba(255, 255, 255, 0.75);
}
.ReactModal__Overlay.with-bottom-margin{
  margin-bottom:120px;
}