@media (max-width: 1500px) {

	h1 span {
        font-size: 3.5rem;
    }

    header .top-panel:before,
    header .top-panel:after,
    #player:before,
    #player:after {
        width: 500px;
        height: 500px;
    }
    #auth-panel .logo img {
        width: 15rem;
        margin: 6rem 0 4rem 0;
    }

    #audio-recording .audio-desc {
        padding: 0 0 0 1rem;
    }
    
    #audio-recording .audio-desc p.info, 
    .modal-body .audio-desc p.info {
        font-size: 2rem;
    }

    #audio-recording .audio-desc .description *{
        font-size: 1.4rem;
    }

    #audio-recording .audio-desc-container .description{
        max-height: 35vh;
    }

    #play-list *{
        font-size:1.3rem;
    }
    #video-player-overlay .close{
        width:35px;
        top:3rem;
        right:3rem;
    }
    #audio-recording .audio-desc.full .description h1{
        font-size:3rem;
      }
      #audio-recording .audio-desc.full .description h2{
        font-size:2.5rem;
      }
      #audio-recording .audio-desc.full .description h3{
        font-size:2rem;
      }
      #audio-recording .audio-desc.full .description h4,
      #audio-recording .audio-desc.full .description h5,
      #audio-recording .audio-desc.full .description h6{
        font-size:1.8rem;
      }

}