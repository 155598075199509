@media (max-width: 767px) {

    header {
        z-index: 10;
        padding: 0 0 0 0rem;
    }

    header .menu-icon{
        display:flex;
        align-items: center;
      }
    header .top-panel:before {
        left: 0px;
    }

    header .side-bar {
        width: 100%;
        position: fixed;
        left: 0;
        top: 46px;
        transform: translateX(0%);
        transition: transform 1s;
    }
    header .side-bar.closed {
        transform: translateX(-100%);
    }
	
	header .top-panel:before, 
    header .top-panel:after, 
    #player:before, 
    #player:after {
        width: 400px;
        height: 400px;
    }
    header .top-panel li ul{
        top:3.9rem;
      }

    #player .progress-bar-content div.progress-bar,
    #player .progress-bar-content div.progress-bar .progress {
        width: 400px;
    }

    header .side-bar .categories {
        display: flex;
        flex-wrap: wrap;
        padding-bottom:120px;
    }
    header .side-bar .course {
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        min-height:220px;
    }
    header .side-bar .course:nth-child(2n - 1) {
        border-right:1px solid var(--line-color);
    }

    header .side-bar{
        box-shadow:none;
        border-top: 1px solid var(--line-color);
    }

    header .side-bar:before,
    header .side-bar:after {
        width: 250px;
        height: 250px;
    }

    #play-list .static {
        width: 72px;
    }

    #play-list .pl-item div {
        padding: 0 0.5rem;
    }

    #video-player-overlay {
        left: 0px;
    }
    #payment-form .payment-button-container {
        margin-top: 0rem;
    }
    #payment-form .membership .price-content{
        flex:0 0 100%;
        max-width:100%;
        margin: 0;
    }

    #payment-form .membership .price{
        width:100%;
        margin-bottom:2rem;
        padding:1rem;
    }
    #auth-panel .langs div.all-langs .container{
        width: 576px;
        max-width: 576px;
    }
    #auth-panel .langs div.all-langs ul li{
		max-width:calc(33% - 2rem);
		flex:0 0 calc(33% - 2rem);
		cursor:pointer;
		margin: 0 1rem 1rem 1rem;
	}
    .l-content {
        padding: 0 1.5rem 0 1.5rem;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
	